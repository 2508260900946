module.exports = {
  siteTitle: 'Benymar',
  siteTitleAlt: 'Blog de Benymar Centro de Desarrollo Humano y Psicoterapia', // This allows an alternative site title for SEO schema.
  publisher: 'Benymar Centro de Desarrollo Humano y Psicoterapia', // Organization name used for SEO schema
  siteDescription:
    'En este espacio podrás encontrar investigaciones, artículos y notas que conciernen a la psicología,',
  siteUrl: 'https://blog.benymar.com.mx', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomePage: 7, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 5, // Number of posts shown on paginated pages
  author: 'Maricela Aguilar Flores', // Author for RSS author segment and SEO schema
  authorUrl: 'https://gcn.netlify.com/about/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@DMSBenymar', // Change for Twitter Cards
  shortTitle: 'Benymar', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: `Copyright © ${new Date().getFullYear()} Benymar Centro de Desarrollo Humano y Psicoterapia`, // Copyright string for the RSS feed
}
